import axios from 'axios'
import date from 'date-and-time';
// import { prepareOrderRowHelper } from '../data/helpers/prepareOrderRowHelper'
// import { getAreaListApi } from './areaListApi'
// import { getCityListApi } from './cityListApi'
import { getManagerListApi } from './managerListApi'
// import orders from './importOrdersUA.json'
import db from './../firebase/firebase';
// import promocodesList from '../api/promocodesList.json'
import cityListData from '../old/cityListData.json'
import { BACKEND_DOMAIN } from './../data/constants/statuses';
import { prepareJuridicalOrderRowHelper, prepareKaspiOrderRowHelper } from '../data/helpers/prepareOrderRowHelper';
import { prepareWildberriesOrderRowHelper } from './../data/helpers/prepareOrderRowHelper';
import { marketNameList } from '../data/constants/data';

export const addOrderApi = async (userData, managerList) => {

    const { token } = userData

    const resOrders = await getMonthOrderListApi(2021, 7, managerList)
    // const resOrders = await getOrderListShopApi()
    // console.log("🚀 ~ file: orderListApi.js ~ line 69 ~ addOrderApi ~ resOrders", resOrders)

    // Object.values(resOrders).forEach((item, index) => {


    //     axios
    //         .post('http://localhost:133/orders', {...item, locale: "uk-UA"},
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`
    //                 }
    //             }).then(response => {
    //                 console.log(response);
    //             });
    // })
}

export const getMonthOrderListApi = async (userData, year, month) => {
    const { token, managerId, managerLocale, managerRole } = userData;
    let requestUrl = `${BACKEND_DOMAIN}/orders?_locale=${managerLocale}&_limit=-1&orderYear=${year}&orderMonth=${month}`;

    if (managerRole === "marketman") {
        const shopSources = marketNameList.map(source => `shopSource_in=${source}`).join('&');
        requestUrl += `&${shopSources}`;
    } else {
        requestUrl += `&manager=${managerId}`;
    }

    const res = axios.get(requestUrl, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.data;
    });

    return res;
}

export const getAdminMonthOrderListApi = async (userData, year, month, managerId, location, source) => {
    console.log("🚀 ~ file: orderListApi.js ~ line 52 ~ getAdminMonthOrderListApi ~ managerId", managerId)
    const { token } = userData
    const allManagers = managerId === 0 ? '' : `&manager=${managerId}`
    const allSources = source === 'all' ? '' : `&shopSource=${source}`
    const res = axios
        .get(`${BACKEND_DOMAIN}/orders?_locale=${location}${allManagers}&_limit=-1&orderYear=${year}&orderMonth=${month}${allSources}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

// temporarily for upload cities
// export const addCitiesApi = async (userData) => {

//     const {token} = userData

//     Object.entries(cityListData).forEach((item, index) => {
// const city = item[0]
// const region = item[1]

//         axios
//             .post('http://localhost:1337/cities', {city: city, region: region, locale: "uk-UA"},
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 }).then(response => {
//                     console.log(response);
//                 });
//     })
// }

export const getRegionListApi = async (userData, location) => {
    const { token } = userData
    const res = await axios
        .get(`${BACKEND_DOMAIN}/regions?_limit=-1&_locale=${location}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const addJuridicalOrderApi = async (userData) => {

    const { token } = userData
    const location = 'ru-RU';
    const regionList = await getRegionListApi(userData,  location)
    const resOrders = prepareJuridicalOrderRowHelper(regionList)
    // console.log("🚀 ~ file: orderListApi.js ~ line 69 ~ addOrderApi ~ resOrders", resOrders)

    Object.values(resOrders).forEach((item, index) => {
        // console.log("🚀 ~ file: orderListApi.js ~ line 98 ~ Object.values ~ item", item)


        axios.post(`${BACKEND_DOMAIN}/orders`, { ...item, locale: "ru-RU" },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                console.log('response', response);
            });
    })
    const resOrderList = await getAdminMonthOrderListApi(userData, 2024, 8, 0, 'ru-RU', 'all')
    const jurOrderList = await resOrderList.filter(item => item.orderId.startsWith("j-"));
    console.log("🚀 ~ addJuridicalOrderApi ~ jurOrderList:", jurOrderList)

}

const sendRequest = async (item, token, location) => {
    try {
        const response = await axios.post(`${BACKEND_DOMAIN}/orders`, { ...item, locale: location }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log('response', response);
    } catch (error) {
        console.error('Error sending request', error);
    }
};

export const addWildberriesOrderApi = async (userData) => {
    const { token } = userData;
    const regionList = await getRegionListApi(userData);
    const resOrders = prepareWildberriesOrderRowHelper(regionList);

    const requests = Object.values(resOrders).map((item, index) => {
        return new Promise((resolve) => {
            setTimeout(async () => {
                await sendRequest(item, token);
                resolve();
            }, index * 100); // Отправляем запросы с интервалом в 100 мс
        });
    });

    await Promise.all(requests);
};

export const addKaspiOrderApi = async (userData) => {
    const { token } = userData;
    const location = 'kk';
    const regionList = await getRegionListApi(userData, location);
    const resOrders = prepareKaspiOrderRowHelper(regionList);

    const requests = Object.values(resOrders).map((item, index) => {
        return new Promise((resolve) => {
            setTimeout(async () => {
                await sendRequest(item, token, location);
                resolve();
            }, index * 100); // Отправляем запросы с интервалом в 100 мс
        });
    });

    await Promise.all(requests);
};