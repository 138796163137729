import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { updatePromocodeItemAction } from './../data/actions/promocodeActions';
import { ACTIVE_PROMOCODE_STATUS, NEW_PROMOCODE_STATUS } from './../data/constants/statuses';
import PromocodeEditableRow from './PromocodeEditableRow';
import PromocodeReadRow from './PromocodeReadRow';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    position: 'relative',
    top: -11
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 75
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 64,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
});

const EditTablePromocodeList = (props) => {
  const { items, userData, status } = props

  const classes = useStyles();

  const dispatch = useDispatch()

  const [editFormData, setEditFormData] = useState({
  });

  const [editItemId, setEditItemId] = useState(null);

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedItem = {
      doctor: editFormData.doctor,
      clinic: editFormData.clinic,
      city: editFormData.city,
      region: editFormData.region,
      phone: editFormData.phone,
      cardNumber: editFormData.cardNumber,
    };

    dispatch(updatePromocodeItemAction(editItemId, editedItem, userData));
    setEditItemId(null);
  };
  const handleActivateClick = (event, item, itemId) => {
    event.preventDefault();

    const editedItem = {
      activated: true,
    };

    dispatch(updatePromocodeItemAction(itemId, editedItem, userData));
    setEditItemId(null);
  };

  const handleEditClick = (event, item, itemId) => {
    event.preventDefault();
    setEditItemId(itemId);

    const formValues = {
      // manager: item.manager.managerName,
      // promocode: item.promocode,
      doctor: item.doctor,
      clinic: item.clinic,
      city: item.city,
      region: item.region,
      phone: item.phone,
      cardNumber: item.cardNumber,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditItemId(null);
  };

  return (
    <TableContainer component={Paper} className={classes.container} elevation={0}>
      <Table className={classes.table} size="small" aria-label="a dense table" >
        <TableHead className={classes.stickyHeader}>
          <TableRow>
            {/* <TableCell>Менеджер</TableCell> */}
            <TableCell className={classes.tableCell}>Промокод</TableCell>
            <TableCell className={classes.tableCell}>ФИО</TableCell>
            <TableCell className={classes.tableCell}>Клиника</TableCell>
            <TableCell className={classes.tableCell}>Город</TableCell>
            <TableCell className={classes.tableCell}>Регион</TableCell>
            <TableCell className={classes.tableCell}>Номер телефона</TableCell>
            <TableCell className={classes.tableCell}>Номер карты</TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => {
            // console.log("🚀 ~ file: ViewTableDoctorsBonusList.js ~ line 108 ~ ViewTableDoctorsBonusList ~ item", item)
            return (
              <>
                {editItemId === item.id ? (
                  <PromocodeEditableRow
                    editFormData={editFormData}
                    item={item}
                    status={status}
                    handleEditFormChange={handleEditFormChange}
                    handleEditFormSubmit={handleEditFormSubmit}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <PromocodeReadRow
                    item={item}
                    itemId={item.id}
                    status={status}
                    handleEditClick={handleEditClick}
                    handleActivateClick={handleActivateClick}
                  // handleDeleteClick={handleDeleteClick}
                  />
                )}
              </>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EditTablePromocodeList