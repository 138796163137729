import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import PromocodeBonusReadRow from './PromocodeBonusReadRow';
import PromocodeBonusEditableRow from './PromocodeBonusEditableRow';
import { updatePromocodeItemAction, updatePromocodeBonusAction } from '../data/actions/promocodeActions';
import { ACTIVE_PROMOCODE_STATUS, NEW_PROMOCODE_STATUS } from '../data/constants/statuses';
import { yearList } from '../data/constants/dates';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
});

const ViewTableDoctorsBonusList = (props) => {
  const { items, userData, month, year, handleChangeMonth, handleChangeYear } = props

  const classes = useStyles();

  const dispatch = useDispatch()

  const [editFormData, setEditFormData] = useState({
  });

  const [editItemId, setEditItemId] = useState(null);

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const bonusTotalNumber = Number(editFormData.bonusSum) + Number(editFormData.bonusExtra)

    const editedItem = {
      bonusSum: editFormData.bonusSum,
      bonusExtra: editFormData.bonusExtra,
      bonusTotal: bonusTotalNumber

    };

    dispatch(updatePromocodeBonusAction(editItemId, editedItem, userData));
    setEditItemId(null);
  };
  const handleActivateClick = (event, item, itemId) => {
    event.preventDefault();

    const editedItem = {
      activated: true,
    };

    dispatch(updatePromocodeBonusAction(itemId, editedItem, userData));
    setEditItemId(null);
  };

  const handleEditClick = (event, item, itemId) => {
    event.preventDefault();
    setEditItemId(itemId);

    const formValues = {
      manager: item.manager.managerName,
      promocode: item.promocode,
      doctor: item.doctor,
      clinic: item.clinic,
      city: item.city,
      region: item.region,
      phone: item.phone,
      cardNumber: item.cardNumber,
      bonusSum: item.bonusSum,
      bonusExtra: item.bonusExtra
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditItemId(null);
  };

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Месяц</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Год</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
             {yearList.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table" >
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              {/* <TableCell>Менеджер</TableCell> */}
              <TableCell>Промокод</TableCell>
              <TableCell>ФИО</TableCell>
              <TableCell>Клиника</TableCell>
              <TableCell>Город</TableCell>
              <TableCell>Регион</TableCell>
              <TableCell>Номер телефона</TableCell>
              <TableCell>Номер карты</TableCell>
              <TableCell>Месяц</TableCell>
              <TableCell>Бонусы по промокодам</TableCell>
              <TableCell>Доп. бонусы</TableCell>
              <TableCell>Сумма всех бонусов</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.sort((a, b) => (b.bonusSum - a.bonusSum)).map(item => {
              // console.log("🚀 ~ file: ViewTableDoctorsBonusList.js ~ line 108 ~ ViewTableDoctorsBonusList ~ item", item)
              return (
                <>
                  {editItemId === item.id ? (
                    <PromocodeBonusEditableRow
                      editFormData={editFormData}
                      item={item}
                      handleEditFormChange={handleEditFormChange}
                      handleEditFormSubmit={handleEditFormSubmit}
                      handleCancelClick={handleCancelClick}
                    />
                  ) : (
                    <PromocodeBonusReadRow
                      item={item}
                      itemId={item.id}
                      handleEditClick={handleEditClick}
                      handleActivateClick={handleActivateClick}
                    // handleDeleteClick={handleDeleteClick}
                    />
                  )}
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ViewTableDoctorsBonusList