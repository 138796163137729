import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider, Grid } from "@material-ui/core"
import DashboardManagerSalesContainer from '../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    managerName: {
    },
    logoText: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.2,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: 1.4
        },
    },
    link: {
        color: '#ffffff',
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
    },
    menu: {
        position: 'relative',
        top: 5,
        [theme.breakpoints.down('sm')]: {
            top: -2,
            left: 10
        },
    }
}));

const AdminHeaderLayout = () => {
    const classes = useStyles();

    const userData = useSelector(userDataSelector)

    const preventDefault = (event) => event.preventDefault();

    return (
        <AppBar position="fixed">
            <Toolbar>
                <Grid container spacing={1}>
                    <Grid item xs={1} md={1}>
                        <Typography className={classes.logoText}>
                            Perolite<br />Crm
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={11} className={classes.menu}>
                        <Link href="/admin/dashboard" className={classes.link}>
                            Дашборд
                        </Link>
                        <Link href="/admin/allPromocodes" className={classes.link}>
                            Все&nbsp;промокоды
                        </Link>
                        <Link href="/admin/doctorsBonusReport" className={classes.link}>
                            Архив&nbsp;бонусов
                        </Link>
                        <Link href="/admin/orderListReport" className={classes.link}>
                            Заказы
                        </Link>
                        <Link href="/admin/summaryData" className={classes.link}>
                            Аналитика
                        </Link>
                        <Link href="/"
                            className={classes.link}
                            onClick={() => localStorage.removeItem('user')}
                        >
                            Выйти
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default AdminHeaderLayout