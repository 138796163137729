 const mytext =

`
1CClientBankExchange
ВерсияФормата=1.03
Кодировка=Windows
Отправитель=СБИС
ДатаСоздания=03.02.2025
ВремяСоздания=20:01:56
ДатаНачала=03.02.2025
ДатаКонца=03.02.2025
РасчСчет=40702810538000147270
СекцияДокумент=Платежное поручение
Номер=38508
Дата=30.01.2025
Сумма=1734.00
ПлательщикСчет=40702810838000110240
Плательщик=АКЦИОНЕРНОЕ ОБЩЕСТВО "ЦЕНТР ВНЕДРЕНИЯ "ПРОТЕК"
ПлательщикИНН=7724053916
Плательщик1=АКЦИОНЕРНОЕ ОБЩЕСТВО "ЦЕНТР ВНЕДРЕНИЯ "ПРОТЕК"
ПлательщикРасчСчет=40702810838000110240
ПлательщикБанк1=ПАО Сбербанк
ПлательщикБанк2=Москва
ПлательщикБИК=044525225
ПлательщикКорсчет=30101810400000000225
ПолучательСчет=40702810538000147270
ДатаПоступило=30.01.2025
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата за товар по сч/ф  №755474325/1(755474325) от 02-ОКТ-24, В т.ч. НДС 289.00
НазначениеПлатежа1=Оплата за товар по сч/ф  №755474325/1(755474325) от 02-ОКТ-24, В т.ч. НДС 289.00
ПлательщикКПП=997350001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=208526
Дата=31.01.2025
Сумма=89949.90
ПлательщикСчет=40702810154400102828
Плательщик=АКЦИОНЕРНОЕ ОБЩЕСТВО "ФАРМПЕРСПЕКТИВА"
ПлательщикИНН=4028069779
Плательщик1=АКЦИОНЕРНОЕ ОБЩЕСТВО "ФАРМПЕРСПЕКТИВА"
ПлательщикРасчСчет=40702810154400102828
ПлательщикБанк1=ПОВОЛЖСКИЙ БАНК ПАО СБЕРБАНК
ПлательщикБанк2=САМАРА
ПлательщикБИК=043601607
ПлательщикКорсчет=30101810200000000607
ПолучательСчет=40702810538000147270
ДатаПоступило=31.01.2025
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=За косметическую продукцию по сч/ф N7328 от 19.08.2024, N7377 от 27.08.2024, N7413 от 02.09.2024, N7453 от 09.09.2024 в т.ч. НДС 20% - 14991,66р.
НазначениеПлатежа1=За косметическую продукцию по сч/ф N7328 от 19.08.2024, N7377 от 27.08.2024, N7413 от 02.09.2024, N7453 от 09.09.2024 в т.ч. НДС 20% - 14991,66р.
ПлательщикКПП=402801001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=38802
Дата=31.01.2025
Сумма=754.00
ПлательщикСчет=40702810838000110240
Плательщик=АКЦИОНЕРНОЕ ОБЩЕСТВО "ЦЕНТР ВНЕДРЕНИЯ "ПРОТЕК"
ПлательщикИНН=7724053916
Плательщик1=АКЦИОНЕРНОЕ ОБЩЕСТВО "ЦЕНТР ВНЕДРЕНИЯ "ПРОТЕК"
ПлательщикРасчСчет=40702810838000110240
ПлательщикБанк1=ПАО Сбербанк
ПлательщикБанк2=Москва
ПлательщикБИК=044525225
ПлательщикКорсчет=30101810400000000225
ПолучательСчет=40702810538000147270
ДатаПоступило=31.01.2025
Получатель=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательИНН=7716859156
Получатель1=Общество с ограниченной ответственностью "ПЕРОЛАЙТ"
ПолучательРасчСчет=40702810538000147270
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата за товар по сч/ф  №755677457/1(755677457) от 03-ОКТ-24, В т.ч. НДС 125.67
НазначениеПлатежа1=Оплата за товар по сч/ф  №755677457/1(755677457) от 03-ОКТ-24, В т.ч. НДС 125.67
ПлательщикКПП=997350001
ПолучательКПП=772101001
Очередность=5
КонецДокумента
КонецФайла
`

export default mytext